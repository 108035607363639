import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const UserPage = lazy(() =>
	import("./user/UserPage")
);

const TarefaPage = lazy(() =>
	import("./tarefa/TarefaPage")
);

const FuncionarioPage = lazy(() =>
	import("./funcionario/FuncionarioPage")
);

const DocumentoPage = lazy(() =>
	import("./documento/DocumentoPage")
);

const FornecedorPage = lazy(() =>
	import("./fornecedor/FornecedorPage")
);

const CategoriaPage = lazy(() =>
	import("./categoria/CategoriaPage")
);

const ItemPage = lazy(() =>
	import("./item/ItemPage")
);

const ProdutoPage = lazy(() =>
	import("./produto/ProdutoPage")
);

const RequisicaoPage = lazy(() =>
	import("./requisicao/RequisicaoPage")
);

const RequisicaoPendentePage = lazy(() =>
	import("./requisicao-pendente/RequisicaoPendentePage")
);

const RequisicaoOrcamentoParaAprovacaoPage = lazy(() =>
	import("./requisicao-orcamento-para-aprovacao/RequisicaoOrcamentoParaAprovacaoPage")
);

const PedidoPage = lazy(() =>
	import("./pedido/PedidoPage")
);

const RequisicaoOrcamentoReprovadoPage = lazy(() =>
	import("./requisicao-orcamento-reprovado/RequisicaoOrcamentoReprovadoPage")
);

const RequisicaoOrcamentoProdutoExcluidoPage = lazy(() =>
	import("./requisicao-orcamento-produto-excluido/RequisicaoOrcamentoProdutoExcluidoPage")
);

const ContaPage = lazy(() =>
	import("./conta/ContaPage")
);

const ReceitaPage = lazy(() =>
	import("./receita/ReceitaPage")
);

const ClientePage = lazy(() =>
	import("./cliente/ClientePage")
);

const OrcamentoPage = lazy(() =>
	import("./orcamento/OrcamentoPage")
);

const DespesaPage = lazy(() =>
	import("./despesa/DespesaPage")
);

const ObraPage = lazy(() =>
	import("./obra/ObraPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const PerfilPage = lazy(() =>
	import("./perfil/PerfilPage")
);

const TipoDeOcorrenciaPage = lazy(() =>
	import("./tipoDeOcorrencia/TipoDeOcorrenciaPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/users" component={UserPage} />
				<Route path="/tarefas" component={TarefaPage} />
				<Route path="/funcionarios" component={FuncionarioPage} />
				<Route path="/documentos" component={DocumentoPage} />
				<Route path="/fornecedores" component={FornecedorPage} />
				<Route path="/categorias" component={CategoriaPage} />
				<Route path="/itens" component={ItemPage} />
				<Route path="/contas" component={ContaPage} />
				<Route path="/receitas" component={ReceitaPage} />
				<Route path="/clientes" component={ClientePage} />
				<Route path="/orcamentos" component={OrcamentoPage} />
				<Route path="/despesas" component={DespesaPage} />
				<Route path="/obras" component={ObraPage} />
				<Route path="/relatorios" component={RelatorioPage} />
				<Route path="/perfil" component={PerfilPage} />
				<Route path="/tipos-de-ocorrencia" component={TipoDeOcorrenciaPage} />

				<Route path="/produtos" component={ProdutoPage} />
				<Route path="/requisicoes" component={RequisicaoPage} />
				<Route path="/requisicoes-pendentes" component={RequisicaoPendentePage} />
				<Route path="/requisicao-orcamentos-para-aprovacao" component={RequisicaoOrcamentoParaAprovacaoPage} />
				<Route path="/pedidos" component={PedidoPage} />
				<Route path="/requisicao-orcamentos-reprovados" component={RequisicaoOrcamentoReprovadoPage} />
				<Route path="/requisicao-orcamentos-produtos-excluidos" component={RequisicaoOrcamentoProdutoExcluidoPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
