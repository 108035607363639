export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Comercial",
				root: true,
				bullet: "dot",
				icon: "fas fa-money-bill-alt",
				submenu: [
					{
						title: "Clientes",
						bullet: "dot",
						page: "clientes"
					},
					{
						title: "Orçamentos",
						bullet: "dot",
						page: "orcamentos"
					},
				]
			},
			{
				title: "Compras",
				root: true,
				bullet: "dot",
				icon: "fas fa-shopping-cart",
				submenu: [
					{
						title: "Produtos",
						bullet: "dot",
						page: "produtos"
					},
					{
						title: "Requisições",
						bullet: "dot",
						page: "requisicoes"
					},
					{
						title: "Requisições pendentes",
						bullet: "dot",
						page: "requisicoes-pendentes"
					},
					{
						title: "Orçamentos p/ aprovação",
						bullet: "dot",
						page: "requisicao-orcamentos-para-aprovacao"
					},
					{
						title: "Pedidos",
						bullet: "dot",
						page: "pedidos"
					},
					{
						title: "Orçamentos reprovados",
						bullet: "dot",
						page: "requisicao-orcamentos-reprovados"
					},
					{
						title: "Orçament. produtos excluídos",
						bullet: "dot",
						page: "requisicao-orcamentos-produtos-excluidos"
					},
				]
			},
			{
				title: "Operacional",
				root: true,
				bullet: "dot",
				icon: "fas fa-briefcase",
				submenu: [
					{
						title: "Obras",
						bullet: "dot",
						page: "obras"
					},
					{
						title: "Tarefas",
						bullet: "dot",
						page: "tarefas"
					},
					{
						title: "Funcionários",
						bullet: "dot",
						page: "funcionarios"
					},
					{
						title: "Fornecedores",
						bullet: "dot",
						page: "fornecedores"
					},
					{
						title: "Documentos",
						bullet: "dot",
						page: "documentos"
					},
				]
			},
			{
				title: "Financeiro",
				root: true,
				bullet: "dot",
				icon: "fas fa-cash-register",
				submenu: [
					{
						title: "Contas",
						bullet: "dot",
						page: "contas"
					},
					{
						title: "Receitas",
						bullet: "dot",
						page: "receitas"
					},
					{
						title: "Despesas",
						bullet: "dot",
						page: "despesas"
					},
				]
			},
			{
				title: "Minha empresa",
				root: true,
				bullet: "dot",
				icon: "fas fa-building",
				submenu: [
					{
						title: "Categorias",
						bullet: "dot",
						page: "categorias"
					},
					{
						title: "Itens",
						bullet: "dot",
						page: "itens"
					},
					{
						title: "Perfil",
						bullet: "dot",
						page: "perfil"
					},
					{
						title: "Tipos de ocorrência",
						bullet: "dot",
						page: "tipos-de-ocorrencia"
					},
				]
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Financeiro",
						bullet: "dot",
						page: "relatorios/relatorio01"
					},
					{
						title: "Orçamentos",
						bullet: "dot",
						page: "relatorios/relatorio02"
					},
					{
						title: "Documento",
						bullet: "dot",
						page: "relatorios/relatorio03"
					},
					{
						title: "Retenções",
						bullet: "dot",
						page: "relatorios/relatorio04"
					},
					{
						title: "Checkpoints de obras",
						bullet: "dot",
						page: "relatorios/relatorio05"
					},
					{
						title: "Despesas",
						bullet: "dot",
						page: "relatorios/relatorio06"
					},
					{
						title: "Receitas",
						bullet: "dot",
						page: "relatorios/relatorio07"
					},
					{
						title: "Funcionários",
						bullet: "dot",
						page: "relatorios/relatorio08"
					},
					{
						title: "Compras - Orçamentos",
						bullet: "dot",
						page: "relatorios/relatorio09"
					},
					{
						title: "Compras",
						bullet: "dot",
						page: "relatorios/relatorio10"
					},
				]
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-users",
				page: "users",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
